import React, {useEffect} from 'react';
import ServerConnect, {getTime} from "../service";
import {useDispatch, useSelector} from "react-redux";
import {setRatings} from "../store/mainReducer";

const Ratings = ({go}) => {

    const dispatch = useDispatch()
    const ratings = useSelector(state => state.main.ratings)
    const user = useSelector(state => state.main.user)

    useEffect(() => {
        get_ratings()
    }, [])

    const get_ratings = () => {
        ServerConnect.sendGet('/api/ratings/'+user.id)
            .then(data => dispatch(setRatings(data)))
    }

    let content = []

    function sort_ratings() {
        for (let i of ratings) {
           let clName = user.id === i.vk_id ? 'user_rating cur_user_rate' : 'user_rating';
            content.push(
                <div className={clName}>
                    <div className={'user_rating_blocks count'}>{i.row}</div>
                    <div className={'user_rating_blocks'}>
                        <img className={'user_photo'} src={i.url_photo} alt=""/>
                    </div>
                    <div className={'user_rating_name_block user_name'}>
                        <p>{i.first_name} {i.last_name}</p>
                    </div>
                    <div className={'user_rating_blocks rating_score'}>{getTime(i.best_score)}</div>
                </div>
            )
        }
    }

    if (ratings) {
        sort_ratings();
    }

    return (
        <div className='main_panel'>
            <div className={'header_panel ratings_header'}>
                <div id='ratings_arrow'>
                    <div id='arrow_button'>
                        <button className={'button_clear'} onClick={() => go('best_score')}>
                            <img width='20px' src="images/arrow.png" alt=""/>
                        </button>
                    </div>
                </div>
                <div>
                    <p className='big_txt'>Рейтинг</p>
                </div>
            </div>
            <div id='users_ratings'>
                {content}
            </div>
            <div className='footer_logo'><img className='logo_michelin' src="images/logo_michelin.png" alt=""/></div>
            <div className='footer_logo_mobile'><img className='logo_michelin' src="images/logo_michelin_mobile.png"
                                                     alt=""/></div>
        </div>
    );
};

export default Ratings;
