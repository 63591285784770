import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        timer: {hour:0, min:0, sec:0},
        activePanel: 'init',
        user: null,
        ratings: null
    },
    reducers:{
        setTimer(state, action) {
            state.timer = action.payload
        },
        setActivePanel(state,action) {
            state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setRatings(state, action){
            state.ratings = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setTimer,setActivePanel,setUser,setUserInfo,setRatings} = mainSlice.actions
