import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import ServerConnect, {getTime} from "../service";
import bridge from '@vkontakte/vk-bridge';

const BestScore = ({go}) => {

    const user = useSelector(state=>state.main.user)

    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        userGameInfo()
    }, [])


    const userGameInfo = () => {
        ServerConnect.sendGet('/api/user/'+user['id'])
            .then(data => setUserInfo(data))
    }
    const share = () => {
        let message = 'Эта игра такая же скучная,' +
            ' как зимняя дорога с шинами MICHELIN X-Ice Snow.' +
            '  Убедись в этом сам! Моё лучшее время: '+getTime(userInfo.best_score);
          bridge.send("VKWebAppShowWallPostBox",{
            "message": message,
            "attachments": "https://vk.com/app7957037",
            "link_button": "open_url",
            "link_title": "Самая скучная игра"
          });
    }
    return (
        <div className={'main_panel'}>
            <div className={'header_best_score_panel'}>
                <div>
                    <p className={'big_txt'}>Лучший результат</p>
                    <p className={'time_txt'}>{getTime(userInfo.best_score)}</p>
                    <p>Твой порядковый номер: {userInfo.id}</p>
                </div>
            </div>
            <div className={'results_txt margin_b'}>
                <p>Поделись результатом,</p>
                <p>чтобы принять участие в розыгрыше</p>
            </div>
            <div className='buttons_block_best_score'>
                <button className={'button_filled btn-m1 button_best_score'} onClick={share}>Поделись результатом</button>
                <button className={'button_filled btn-m1 button_best_score'} onClick={()=>go('game')}>Играть еще раз</button>
            </div>
            <div className='buttons_block_best_score'>
                <button className={'button_bordered btn-m1 button_best_score'} onClick={()=>go('ratings')}>Рейтинг</button>
                <button className={'button_bordered btn-m1 button_best_score'}><a target='_blank' href="https://vk.com/doc4677742_618967773?hash=976bedcfc169db7bd7&dl=c3cab1957f07061d2f">Правила розыгрыша</a></button>
            </div>

            <div className='buttons_block_best_score_mobile'>
                    <div><button className={'button_filled button_best_score'} onClick={share}>Поделись результатом</button></div>
                    <div><button className={'button_filled button_best_score'} onClick={()=>go('ratings')}>Рейтинг</button></div>
                    <div><button className={'button_bordered button_best_score'} onClick={()=>go('game')}>Повторить</button></div>
                    <div><a target="_blank" href="https://vk.com/doc4677742_618967773?hash=976bedcfc169db7bd7&dl=c3cab1957f07061d2f">Условия конкурса</a></div>
            </div>

            <div className='footer_logo'><img className='logo_michelin' src="images/logo_michelin.png" alt=""/></div>
            <div className='footer_logo_mobile'><img className='logo_michelin' src="images/logo_michelin_mobile.png" alt=""/></div>
        </div>
    );
};


export default BestScore;