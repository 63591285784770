import React, {useEffect} from 'react'
import bridge from "@vkontakte/vk-bridge";

import Home from './panels/Home'
import Init from "./panels/Init";
import Ratings from "./panels/Ratings";
import MainGame from "./panels/MainGame";
import Results from "./panels/Results";
import BestScore from "./panels/BestScore";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "./store/mainReducer";


function App() {
    const dispatch = useDispatch()
    const activePanel = useSelector(state => state.main.activePanel)
    const user = useSelector(state=>state.main.user)


    useEffect(()=>{
        async function fetchData() {
            const user = await bridge.send('VKWebAppGetUserInfo');
            if (user) {
                return user;
            }
        }
        fetchData().then(data => dispatch(setUser(data)))
    },[])

    let content = []
    let wrapper_class = 'init'

    function go (id) {
        dispatch(dispatch(setActivePanel(id)))
    }

    if (activePanel==="home"){
        wrapper_class = 'home'
        content.push(<Home user={user} go={go}/>)
    } else if (activePanel==='init'){
        wrapper_class = 'init'
        content.push(<Init go={go}/>)
    } else if (activePanel==='game'){
        wrapper_class = 'game'
        content.push(<MainGame user={user} go={go}/>)
    } else if (activePanel==='ratings'){
        wrapper_class = 'ratings'
        content.push(<Ratings go={go}/>)
    } else if (activePanel==='results'){
        wrapper_class = 'results'
        content.push(<Results go={go}/>)
    } else if (activePanel==='best_score'){
        wrapper_class = 'best_score'
        content.push(<BestScore user={user} go={go}/>)
    }


  return (
    <div className={'page-wrapper '+wrapper_class}>
        {content}
    </div>
  );
}

export default App;