import 'core-js/features/map'
import 'core-js/features/set'
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';

bridge.send("VKWebAppInit");
bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#fff"});



ReactDOM.render(
    <Provider store={store}>
        <StrictMode>
            <App />
        </StrictMode>
    </Provider>,
  document.getElementById('root')
);