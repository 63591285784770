import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import ServerConnect from "../service";

const Results = ({go}) => {

    const time_score = useSelector(state => state.main.timer)
    const user = useSelector(state=>state.main.user)

    useEffect(() => {
        sendResult()
    }, [])


    function sendResult() {
        let context = {
            vk_id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            url_photo: user.photo_100,
            best_score: time_score.hour * 3600 + time_score.min * 60 + time_score.sec
        }
        ServerConnect.sendPost('/api/user/' + user.id, context);
    }

    return (
        <div className={'main_panel'}>
            <div className={'header_results_panel margin_b'}>
                <div>
                    <p className={'result_header_text'}>Твой результат:</p>
                    <p className={'time_txt'}>{time_score.hour.toString().padStart(2,'0')}:
                                              {time_score.min.toString().padStart(2,'0')}:
                                              {time_score.sec.toString().padStart(2,'0')}</p>
                </div>
                <div><button className={'repeat_btn'} onClick={()=>go('game')}>Повторить</button></div>
            </div>
            <div className='results_tyre_mobile'><img className='img_tyre_mobile' src="images/results_tyre_mobile3.png" alt=""/></div>
            <div className="text_res">
                <div className='result_main_txt'>
                    <p>Игра была такой же скучной,</p>
                    <p>как поездка с шинами</p>
                    <p><strong style={{color:'#FCE500'}}>MICHELIN X-Ice Snow</strong></p>
                </div>
                <div><button className='button_filled btn_res' onClick={()=>go('best_score')}>Участвовать в розыгрыше</button></div>
                <div>
                    <button className='button_bordered btn_res'>
                        <a target='_blank' href="https://www.michelin.ru/auto/tyres/michelin-x-ice-snow?utm_source=vk&utm_medium=soc&utm_campaign=B2C-LDS-NOTPREDEF-Winter_2021_XIN4_XIS&utm_content=MICHELIN/AGB/Caring/PDT/WinterRange|92776272/Winter_2021_XIN4_XIS&utm_term=vk_mini_app">Подробнее про шины</a>
                    </button>
                </div>
                {/*<div><a target='_blank' href="https://www.michelin.ru/auto/tyres/michelin-x-ice-snow?utm_source=vk&utm_medium=soc&utm_campaign=B2C-LDS-NOTPREDEF-Winter_2021_XIN4_XIS&utm_content=MICHELIN/AGB/Caring/PDT/WinterRange|92776272/Winter_2021_XIN4_XIS&utm_term=vk_mini_app">Подробнее про шины</a></div>*/}
            </div>
            <div className="results_tyre"><img className='img_tyre' src="images/results_footer.png" alt=""/></div>
            <div className='footer_logo'><img className='logo_michelin' src="images/logo_michelin.png" alt=""/></div>
            <div className='footer_logo_mobile'><img className='logo_michelin' src="images/logo_michelin_mobile.png" alt=""/></div>
        </div>
    );
};

export default Results;