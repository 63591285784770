const api_url = 'https://mich.techbot24.ru'
const params = window.location.search;

async function postResource(url = '', data = {}) {
    let response = await fetch(
        api_url + url + params,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response) {
        throw new Error(`Неполучилось отправить запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}


async function GetResource(url) {

    let response = await fetch(api_url + url);
    if (!response) {
        throw new Error(`Не получилось получить данные ${url}, статус ${response.status}`)
    }
    return await response.json()
}

const ServerConnect = {
    sendPost: postResource,
    sendGet: GetResource
}

export default ServerConnect;

export const getTime = (sec) => {
    let hours = Math.floor(sec / 60 / 60);
    let minutes = Math.floor(sec / 60) - (hours * 60);
    let seconds = sec % 60;
    let formatted = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
    return formatted
}
