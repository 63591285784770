import React  from 'react';


const Home = ({go}) => {


    const navigate = (e) => {
        const id = e.currentTarget.id;
        if (id === 'b1') {
            go('game');
        } else if (id === 'b2') {
            go('ratings');
        } else if (id === 'b3')
        {
            go('results');
        }
    }


    return (
        <div className={'main_panel'}>
            <div className={'header_panel margin_b'}><img className={'borring_game_img'} src="images/maintext.png"
                                             alt="Самая скучная игра"/></div>
            <div className={'main_txt margin_b'}><p>Сыграй в нашу игру,</p>
                <p>поделись результатом</p>
                <p>и прими участие в розыгрыше</p>
                <p>призов!</p></div>
            <div className='home_btn_mob'><button className={'button_filled home_button'} id='b1' onClick={navigate}>ИГРАТЬ!</button></div>
            <div className='footer_logo'><img className='logo_michelin' src="images/logo_michelin.png" alt=""/></div>
            <div className='footer_logo_mobile'><img className='logo_michelin' src="images/logo_michelin_mobile.png" alt=""/></div>
        </div>
    );
};

export default Home
